import React from 'react';
import { Link } from 'gatsby';

import './footer.css';

function Footer() {

    return <ul>
        <li><Link to='/legal'>Legal</Link></li>
        <li className='flexgrow'></li>
        <li>Copyright &copy; Coralpeak Systems Ltd 2021</li>
    </ul>
}

export default Footer;