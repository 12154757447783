import React  from "react";

import PageLayout from "./PageLayout";

function ContentPage({children}) {
    return <PageLayout>
        <div className='content-container'>
            <content>{children}</content>
        </div>
    </PageLayout>
}

export default ContentPage;