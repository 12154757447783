import React  from "react";
import { Helmet } from "react-helmet";

import Header from '../components/Header';
import Footer from '../components/Footer';

import '../resources/layout.css';
import '../resources/styles.css';

function PageLayout({children}) {

    return <>
        <Helmet>
            <meta charSet='utf-8' />
            <meta name='description' content='Web application development and support services for business.' />
            <title>Coralpeak Systems</title>
        </Helmet>
        <header><Header /></header>
        <main>
            {children}
        </main>
        <footer><Footer /></footer>
    </>
}

export default PageLayout;