import React from 'react';
import  { Link } from 'gatsby';

import logo from '../images/logo85.png';

import './header.css';

function Header() {

    return <nav>
        <ul>
            <li className="logo"><Link to="/"><img src={logo} alt='Home' /></Link></li>
            <li className="companyname hidemobile">Coralpeak Systems</li>
            <li className='flexgrow'></li>
            <li className="navitem"><Link to="/services">Services</Link></li>
            <li className="navitem"><Link to="/about">About</Link></li>
            <li className="navitem"><Link to="/contact">Contact</Link></li>
        </ul>
    </nav>
}

export default Header;